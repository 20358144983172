import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ImgUsersSvg } from './image';

import {
  breakpoints,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
  SECRET_PASSPHRASE,
  SHORT_DATE_TIME,
  TimePeriodValue,
} from './constants';
import CryptoJS from 'crypto-js';
import type { Dayjs } from 'dayjs';
import { store } from '../store/store';
import {
  customerCareNo,
  PremiumCustomerCareNo,
} from '../pages/contact-us/contact-us.page';
import { api } from '../store/api/api';

dayjs.extend(utc);
dayjs.extend(timezone);

export const orderReservationTime = Number(
  process.env.REACT_APP_ORDER_RESERVATION_TIME!
);

export const insert = (arr: any[], index: number, ...newItems: any[]) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];

export const vw = Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
);

export const isScreenSmaller = vw <= Number(breakpoints.md.replace('px', ''));

export const isScreenLarger = vw >= Number(breakpoints.lg.replace('px', ''));

export const round = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const formatDateTime = (dateString?: string) => {
  if (dateString) {
    return dayjs(dateString).format(DISPLAY_DATE_TIME_FORMAT);
  }
  return null;
};

export const formatShortDateTime = (dateString?: string) => {
  if (dateString) {
    return dayjs(dateString).format(SHORT_DATE_TIME);
  }
  return null;
};

export const formatDate = (dateString?: string) => {
  if (dateString) {
    return dayjs(dateString).format(DISPLAY_DATE_FORMAT);
  }
  return null;
};

export const getFromTo = (period: TimePeriodValue) => {
  const nowString = dayjs().endOf('day').toISOString();

  if (period === 'today') {
    return [dayjs().startOf('day').toISOString(), nowString];
  } else if (period === 'yesterday') {
    return [
      dayjs().subtract(1, 'day').startOf('day').toISOString(),
      dayjs().subtract(1, 'day').endOf('day').toISOString(),
    ];
  } else if (period === 'thisWeek') {
    return [dayjs().startOf('week').startOf('day').toISOString(), nowString];
  } else if (period === 'thisMonth') {
    return [dayjs().startOf('month').startOf('day').toISOString(), nowString];
  } else {
    return ['', ''];
  }
};

export const getReadableTime = (time?: number) => {
  if (!time) {
    return '0';
  }
  const hours = Math.floor(time / 3600);
  const remainingTime = time - hours * 3600;
  const minutes = Math.floor(remainingTime / 60);
  const seconds = Math.floor(remainingTime - minutes * 60);

  return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''} ${
    seconds ? `${seconds}s` : ''
  }`
    .replaceAll('  ', ' ')
    .trim();
};

export const getMaxTimeUnit = (time?: number) => {
  if (!time) {
    return null;
  }
  const days = Math.floor(time / 86400);
  if (days) {
    return { label: 'DAYS', short: ' days', value: 'days', count: days };
  }
  const hours = Math.floor(time / 3600);
  if (hours) {
    return { label: 'HOURS', short: 'h', value: 'hours', count: hours };
  }
  const remainingTime = time - hours * 3600;
  const minutes = Math.floor(remainingTime / 60);
  if (minutes) {
    return { label: 'MINUTES', short: 'm', value: 'minutes', count: minutes };
  }
  const seconds = Math.floor(remainingTime - minutes * 60);
  if (seconds) {
    return { label: 'SECONDS', short: 's', value: 'seconds', count: seconds };
  }
  return null;
};

export const capitalize = (text?: string) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
};

export const encrypt = (text?: string | null | undefined | number) => {
  if (text) {
    const b64 = CryptoJS.AES.encrypt(
      String(text),
      SECRET_PASSPHRASE
    ).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    const eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }
  return '';
};

export const encryptedOrgIds: { [x: string]: string } = {};

export const encryptOrgId = (orgId: string | number) => {
  const orgIdString = String(orgId);
  if (encryptedOrgIds[orgIdString]) {
    return encryptedOrgIds[orgIdString];
  }
  const encyptedOrgId = encrypt(orgIdString);
  encryptedOrgIds[orgIdString] = encyptedOrgId;
  return encyptedOrgId;
};

export const decrypt = (text?: string | null | undefined | number) => {
  if (text) {
    const reb64 = CryptoJS.enc.Hex.parse(String(text));
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, SECRET_PASSPHRASE);
    const plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  }
  return '';
};

export const convertToString = (...args: any[]) =>
  args.map((e) => (e ? e : 'null')).join(',');

export const disableAfterDate = (currentDate: Dayjs) => {
  return currentDate.isAfter(dayjs().endOf('day'));
};

export const disableBeforeDate = (currentDate: Dayjs) => {
  return currentDate.isBefore(dayjs().startOf('day'));
};

export const getEpochTime = (date: Date) => Math.floor(date.getTime() / 1000.0);

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getTimeSecondsFromNow = (seconds: number) =>
  Date.now() + 1000 * seconds;

export const getDeadlineFromDateString = (dateString: string) => {
  const date = dateString ? new Date(dateString) : new Date();
  return date.setSeconds(date.getSeconds() + orderReservationTime);
};

export const getDeadlineFromEpochTime = (epochTime: number) => {
  const date = new Date(0);
  date.setUTCSeconds(epochTime);
  return date.getTime();
};

export enum pendingActionsTypes {
  changeTeamName = 'change-team-name',
  addStaff = 'add-staff',
}

export const Owner = 'Owner';
export const Manager = 'Manager';
export const Member = 'Member';

export const TeamManagement = 'TEAM MANAGEMENT';

export type Role = 'member' | 'owner' | 'manager';

export const convertToLabelValue = (
  obj: Required<Pick<any, 'label' | 'value'>> | undefined | null
) =>
  obj
    ? {
        label: obj.label,
        value: obj.value,
      }
    : undefined;

export const roles = [
  {
    key: 1,
    value: Owner.toLowerCase() as Role,
    label: Owner,
  },
  {
    key: 2,
    value: Manager.toLowerCase() as Role,
    label: Manager,
  },
  {
    key: 3,
    value: Member.toLowerCase() as Role,
    label: Member,
  },
];

const getOSOfUser = () => {
  const userAgent = (window?.navigator?.userAgent || '').toLowerCase();
  if (userAgent) {
    if (userAgent.indexOf('win') !== -1) {
      return 'windows';
    }
    if (userAgent.indexOf('mac') !== -1) {
      return 'macintosh';
    }
    if (userAgent.indexOf('linux') !== -1) {
      return 'linux';
    }
    if (userAgent.indexOf('android') !== -1) {
      return 'android';
    }
    if (userAgent.indexOf('like mac') !== -1) {
      return 'ios';
    }
  }
  return 'unknown';
};

const isiOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const os = getOSOfUser();

export const isiPhone = isiOS();

export type GenericObject = { [key: string]: any };

export const contactUs = (message: string, extraText?: string) => {
  const state = store.getState();
  const userSync = api.endpoints.usersSync.select()(state).data;
  const org = userSync?.org;
  const phone = userSync?.user?.phone || '';
  const isTrial = userSync?.current_subscription?.is_trial || false;
  const isDemo =
    userSync?.current_subscription?.is_active === false &&
    userSync?.current_subscription?.is_trial === false &&
    userSync?.current_subscription?.is_trial_active === false;

  const { id, name } = org || {};
  const sourceText = '(source: OD)';
  const orgIdText = id ? `Org Id: ${id}` : '';
  const orgNameText = name ? `Org Name: ${name}` : '';
  const phoneText = phone ? `Phone: ${phone}` : '';
  const text = `
    ${message}
    ${sourceText}
    ${orgIdText}
    ${orgNameText}
    ${phoneText}
    ${extraText ? `Message: ${extraText}` : ''}
    Source: Web dashboard
    `;

  window.open(
    `https://api.whatsapp.com/send?phone=91${
      isTrial || isDemo ? customerCareNo : PremiumCustomerCareNo
    }&text=${text}`
  );
};

export const chunkArray = (myArray: any[], chunk_size: number) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];
  let myChunk = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const formatISTDate = (dateString: string, format: string) => {
  if (dateString) {
    return dayjs.utc(dateString).tz('Asia/Kolkata').format(format);
  }
  return null;
};

export function formatNumberIndianStd(value: number | string): string | number {
	// Convert valid number-like strings to integers
	if (typeof value === 'string' && !isNaN(Number(value)) && Number.isInteger(Number(value))) {
	  value = Number(value);
	}
  
	if (typeof value === 'number' && Number.isInteger(value)) {
	  const numStr = value.toString();
	  if (numStr.length <= 3) {
		return numStr;
	  }
	  // Split the first three digits and the remaining part
	  const firstPart = numStr.slice(0, -3);
	  const lastPart = numStr.slice(-3);
	  // Format the first part with commas every two digits
	  const formattedFirstPart = firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
	  return formattedFirstPart + "," + lastPart;
	}
	return value; 
  }



