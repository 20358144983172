import { Button, message, Modal } from "antd";
import type { ButtonProps } from "antd";
import {
  useCancelOrderMutation,
  useLazyGetPendingOrderQuery,
  useLazyLoginOrgQuery,
} from "../../store/api/api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  setPurchaseOrgId,
  setRenewOrgId,
  setTopUpOrgId,
  setUpgradeOrgId,
} from "../../store/global/global.reducer";
import { useState } from "react";
import { paidOrderCancelMessage } from "./constants";

export const PayNowButton = (
  props: Readonly<
    ButtonProps & {
      textClassName?: string;
      orgId: number;
    }
  >
) => {
  const { orgId, textClassName, ...buttonProps } = props;
  const dispatch = useDispatch();
  const [cancelOrder] = useCancelOrderMutation();
  const [getPendingOrder] = useLazyGetPendingOrderQuery();
  const [loginOrg] = useLazyLoginOrgQuery();
  const [loading, setLoading] = useState(false);
  const openModal = () => {
    dispatch(setPurchaseOrgId(orgId));
  };

  return (
    <Button
      {...buttonProps}
      loading={loading}
      disabled={loading}
      type="primary"
      onClick={async (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setLoading(true);
        await loginOrg(orgId);
        const pendingOrder = await getPendingOrder();
        const pendingOrderId = pendingOrder?.data?.id || 0;
        if (pendingOrderId) {
          const orderType = pendingOrder?.data?.details?.order_type;

          Modal.confirm({
            title: "You already have a payment in progress",
            icon: <ExclamationCircleOutlined />,
            content: "Cancel and start a new Payment?",
            cancelText: "Continue older payment",
            okText: "Start new",
            onOk: async () => {
              if (pendingOrder?.data?.external_order_status === "paid") {
                message.error(paidOrderCancelMessage);
                return;
              } else {
                await cancelOrder({
                  order_id: pendingOrderId,
                  status: "cancelled",
                });
                openModal();
              }
            },
            cancelButtonProps: {
              onClick: () => {
                if (orderType === "PURCHASE") {
                  dispatch(setPurchaseOrgId(orgId));
                } else if (orderType === "TOPUP") {
                  dispatch(setTopUpOrgId(orgId));
                } else if (orderType === "RENEW") {
                  dispatch(setRenewOrgId(orgId));
                } else if (orderType === "UPGRADE") {
                  dispatch(setUpgradeOrgId(orgId));
                }
                Modal.destroyAll();
              },
            },
            closable: true,
          });
        } else {
          openModal();
        }
        setLoading(false);
      }}
    >
      <span className={textClassName}>Pay Now</span>
    </Button>
  );
};
