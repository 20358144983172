import { createSelector } from "reselect";
import { RootState } from "../store";

export const selectGlobalReducer = (state: RootState) => state.global;

export const selectPurchaseOrgId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.purchaseOrgId;
  }
);

export const selectRenewOrgId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.renewOrgId;
  }
);

export const selectUpgradeOrgId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.upgradeOrgId;
  }
);

export const selectUpgradePlanId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.upgradePlanId;
  }
);

export const selectTopUpOrgId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.topUpOrgId;
  }
);

export const selectGlobalOrgId = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.globalOrgId;
  }
);

export const selectGlobalOrgIds = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.globalOrgIds;
  }
);

export const selectCallInitiated = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer.callInitiated;
  }
);

export const selectSiderCollapsed = createSelector(
  [selectGlobalReducer],
  (sider) => sider.siderCollapsed
);

export const selectOpenKeys = createSelector(
  [selectGlobalReducer],
  (sider) => sider.openKeys
);

export const selectEditMode = createSelector(
  [selectGlobalReducer],
  (org) => org.editMode
);

export const selectStep = createSelector(
  [selectGlobalReducer],
  (callerTune) => callerTune.step
);

export const selectCallerTuneMessage = createSelector(
  [selectGlobalReducer],
  (reducer) => {
    return reducer?.message || "";
  }
);

export const selectCallerTuneLanguage = createSelector(
  [selectGlobalReducer],
  (callerTune) => {
    return callerTune?.language;
  }
);

export const selectCallerTuneGender = createSelector(
  [selectGlobalReducer],
  (callerTune) => {
    return callerTune.gender;
  }
);

export const selectToggleEnableSave = createSelector(
  [selectGlobalReducer],
  (callerTune) => {
    return callerTune.toggleEnableSave;
  }
);

export const selectCopyCallerTuneModal = createSelector(
  [selectGlobalReducer],
  (reducer) => reducer.copyCallerTuneModal
);

export const selectCampaignStepper = createSelector(
  [selectGlobalReducer],
  (reducer) => reducer.campaignStepper
);

export const selectSuperfoneContact = createSelector(
  [selectGlobalReducer],
  (reducer) => reducer.superfoneContact
);

export const selectCampTempData = createSelector(
  [selectGlobalReducer],
  (reducer) => reducer.campaignTemplateData
);
