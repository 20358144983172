import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { rootReducer } from "./root-reducer";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "./api/api";
import { whatsappApi } from "./api/whatsapp.api";
import { campaignApi } from "./api/campaign.api";
export type RootReducer = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, whatsappApi.middleware,campaignApi.middleware]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
